<template>
  <div class="modal fade" id="modal-task-details">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Task Details</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-sm" v-if="task">
            <tr>
              <th>Created At</th>
              <td>{{ task.created_at }}</td>
              <th>Status</th>
              <td>{{ task.status }}</td>
            </tr>
            <tr>
              <th>Assigned To</th>
              <td>{{ task.assignedTo.name }}</td>
              <th>Equipment</th>
              <td>{{ task.equipment ? task.equipment.name : 'N/A' }}</td>
            </tr>
            <tr v-if="task.status === 'Completed'">
              <th>Completed At</th>
              <td>{{ task.completed_at }}</td>
              <th>Completed By</th>
              <td>{{ task.completedBy ? task.completedBy.name : '' }}</td>
            </tr>
            <tr>
              <th>Details</th>
              <td colspan="3" style="white-space: pre-wrap">{{ task.details }}</td>
            </tr>
          </table>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-dark" data-dismiss="modal">Close</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  data() {
    return {
      task: null,
      modal: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-task-details'));
  },
  methods: {
    show(task) {
      this.task = task;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    }
  }
}
</script>