<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">{{ title }}</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Task Information</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Status</label>
                    <select :class="['form-control', {'is-invalid': errorFor('status')}]"
                            v-model="form.status">
                      <option value="New">New</option>
                      <option value="Investigating">Investigating</option>
                      <option value="Completed">Completed</option>
                    </select>
                    <v-errors :errors="errorFor('status')" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Type</label>
                    <select :class="['form-control', {'is-invalid': errorFor('type')}]"
                            v-model="form.type">
                      <option value="">Select Type</option>
                      <option value="Maintenance">Maintenance</option>
                      <option value="Repair">Repair</option>
                      <option value="Supplies">Supplies</option>
                      <option value="Suggestion">Suggestion</option>
                      <option value="Others">Others</option>
                    </select>
                    <v-errors :errors="errorFor('type')" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Assigned To</label>
                    <select :class="['form-control', {'is-invalid': errorFor('assign_to')}]"
                            v-model="form.assign_to">
                      <option value="">Select User</option>
                      <option :value="user.id"
                              v-for="user in users"
                              :key="'user_'+user.id">{{ user.name }}</option>
                    </select>
                    <v-errors :errors="errorFor('assign_to')" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Equipment</label>
                    <select :class="['form-control', {'is-invalid': errorFor('equipment_id')}]"
                            v-model="form.equipment_id">
                      <option value="">N/A</option>
                      <option :value="equipment.id"
                              v-for="equipment in equipments"
                              :key="'equipment_'+equipment.id">{{ equipment.name }}</option>
                    </select>
                    <v-errors :errors="errorFor('equipment_id')" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Details</label>
                    <textarea :class="['form-control', {'is-invalid': errorFor('details')}]"
                              rows="5"
                              v-model="form.details"></textarea>
                    <v-errors :errors="errorFor('details')" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" v-if="form.status === 'Completed'">
                    <label>Completed By</label>
                    <select :class="['form-control', {'is-invalid': errorFor('completed_by')}]"
                            v-model="form.completed_by">
                      <option value="">Select User</option>
                      <option :value="user.id"
                              v-for="user in users"
                              :key="'user2_'+user.id">{{ user.name }}</option>
                    </select>
                    <v-errors :errors="errorFor('completed_by')" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit"
                      class="btn btn-success mr-1"
                      @click.prevent="submit"
                      :disabled="loading">Save</button>

              <router-link :to="{name: 'tasks'}"
                           class="btn btn-secondary">Cancel</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      title: 'Add Task',
      equipments: [],
      users: [],
      form: {
        status: 'New',
        equipment_id: '',
        type: '',
        assign_to: '',
        details: '',
        completed_by: ''
      }
    }
  },
  created() {
    this.axios.get('/equipments')
        .then(res => {
          this.equipments = res.data.data;
        });

    this.axios.get('/users')
        .then(res => {
          this.users = res.data.data;
        });

    if (this.$route.name === 'tasks_edit') {
      this.axios.get('/tasks/'+this.$route.params.id)
          .then((response) => {
            this.form.status = response.data.data.status;
            this.form.equipment_id = response.data.data.equipment_id;
            this.form.type = response.data.data.type;
            this.form.assign_to = response.data.data.assign_to_id;
            this.form.details = response.data.data.details;
            this.form.completed_by = response.data.data.completed_by_id;
          });

      this.title = 'Edit Task';
    }
  },
  methods: {
    submit() {
      this.allErrors = null;
      this.loading = true;

      if (this.$route.name === 'tasks_edit') {
        this.axios.patch('/tasks/'+this.$route.params.id, this.form)
            .then(() => {
              this.$router.push({name: 'tasks'});
              this.showSuccessMsg('Updated Successfully!')
            })
            .catch((err) => {
              this.allErrors = err.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
      } else {
        this.axios.post('/tasks', this.form)
            .then(() => {
              this.$router.push({name: 'tasks'});
              this.showSuccessMsg('Added Successfully!')
            })
            .catch((err) => {
              this.allErrors = err.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
      }
    }
  }
}
</script>